.MuiListItemText-primary {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 500;
}

.MuiDivider-root {
    background: #01004A;
    margin-left: 1rem;
    margin-right: 1rem;
}

.MuiTypography-colorPrimary {
    color: white
}

.MuiBreadcrumbs-li {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.MuiBreadcrumbs-li:hover {
    text-decoration: none;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transition-duration: 100ms;
}

.MuiBreadcrumbs-separator {
    color: white;
}

@layer base {
    @font-face {
        font-family: "stop";
        src: url("stop.ttf") format("truetype");
    }

    @font-face {
        font-family: "sourcesans";
        src: url("sourcesans.ttf") format("truetype");
    }
    @font-face {
        font-family: "oswald";
        src: url("oswald.ttf") format("truetype");
    }
}

.font-heading {
    font-family: oswald, sans-serif;
}

.font-para {
    font-family: sourcesans, sans-serif;
}

.font-stop {
    font-family: stop, sans-serif;
}

.card-look {
    @apply bg-white border rounded-xl shadow-xl p-6 pt-4;
}

.scroll-section {
    scroll-margin: 4.5rem;
}

.content {
    @apply flex flex-col w-11/12 text-primary sm:max-w-screen-md justify-center text-center mx-auto;
}

.section-head {
    @apply mb-2 text-2xl md:text-3xl tracking-wide font-heading font-bold;
}

.selected {
    @apply bg-primary text-white
}

@tailwind base;
@tailwind components;
@tailwind utilities;
